import { throwError, Observable } from 'rxjs'
import { EventEmitter, Injectable } from '@angular/core'
import { OperatorService } from '../core/services/operator.service'
import { Message } from '../shared/message.model'
import { Stream } from '../core/models/stream.model'
import { ContactService } from '../core/services/contact.service'
import { Contact, Tag } from '../core/models/contact.model'
import { GuestAttributes, RoomAssignment, RoomContact } from '../core/models/room-assignment.model'
import { RoomAssignmentService } from '../core/services/room-assignment.service'
import { SiteService } from '../core/services/site.service'
import { MessageService } from '../core/services/message.service'
import { HotelService } from '../core/services/hotel.service'
import { Note } from '../shared/note.model'
import { SelectOption } from 'app/core/models/select-option.model'

@Injectable()
export class OperatorViewService {

  showMessage = true
  currentStreamId: string
  currentStreamVersion: string
  showMessageUpdated: EventEmitter<boolean> = new EventEmitter()
  currentStreamIdUpdated: EventEmitter<Stream> = new EventEmitter()
  currentDeletedStreamIdUpdated: EventEmitter<any> = new EventEmitter()

  constructor(private operatorService: OperatorService,
    private contactService: ContactService,
    private roomAssignmentService: RoomAssignmentService,
    private siteService: SiteService,
    private messageService: MessageService,
    private hotelService: HotelService) {
  }

  setShowMessage(show: boolean): void {
    this.showMessage = show
    this.showMessageUpdated.emit(this.showMessage)
  }

  // 25/02/25 - batchprocess - add created_at,message_id_list_length
  setStreamId(id: string, version: string , importantFlag: boolean=false,created_at:any='',message_id_list_length:any='',subscriptionEnabled:boolean=false,AgeCheck:boolean=false): void {
    this.currentStreamId = id
    this.currentStreamVersion = version
    const stream = new Stream
    stream.stream_id = id
    stream.version = version
    stream.importantFlag = importantFlag
    // 24/02/25 - batchprocess - set message list lenght and stream created date
    stream.create_datenew = created_at
    stream.message_id_list_length = message_id_list_length; // 24/02/25 - Add message_id_list_length
    stream.subscriptionEnabled = subscriptionEnabled // 14/02/25
    stream.AgeCheck = AgeCheck
    this.currentStreamIdUpdated.emit(stream)
  }

  updateCheckOutDate(): void {

  }

  completeStream(): Promise<boolean> {
    const currentDeleteStreamId = this.currentStreamId
    return this.operatorService.deleteStream(this.currentStreamId)
      .then(
        (res) => {
          this.setStreamId('', '')
          this.currentDeletedStreamIdUpdated.emit(currentDeleteStreamId)
          return true
        })
      .catch(
        (err) => {
          console.log(err)
          return throwError('error deleting stream').toPromise()
        })
  }

  sendCsvEmail(conversation: any[], email: string, contact: Contact, phone: string, customer_email: string, linked: RoomContact[]): Promise<boolean> {

    const notes = contact.notes
    const attributes = contact.attributes
    return this.hotelService.sendEmail(conversation, email, phone, customer_email, linked, notes, attributes)
      .then(
        (res) => {

          return true
        })
      .catch(
        (err) => {
          return throwError('error sending email').toPromise()
        })
  }

  sendConversationToActiveCampaign(conversation: any[], phone: string, baseURL: string, apiKey: string, activeCampaignId: number): Promise<boolean> {
    const randomNumber = Math.random()+'_'+phone;
    return this.hotelService.sendConversation(conversation, randomNumber, baseURL, apiKey, activeCampaignId)
      .then(
        (res) => {
          const responseData = JSON.parse(JSON.stringify(res));
          return responseData
        })
      .catch(
        (err) => {
          return throwError('Something went wrong. Please try after sometime.').toPromise()
        })
  }

  getStreamByReasourceIdentifier(ident: string): Promise<boolean> {
    const currentDeleteStreamId = this.currentStreamId
    return this.operatorService.deleteStream(this.currentStreamId)
      .then(
        (res) => {
          this.setStreamId('', '')
          this.currentDeletedStreamIdUpdated.emit(currentDeleteStreamId)
          return true
        })
      .catch(
        (err) => {
          return throwError('error deleting stream').toPromise()
        })
  }

  saveRoomAssignment(contact: Contact, roomAssignment: RoomAssignment, skipRoomGet: boolean = false): Promise<RoomAssignment> {
    let promise: Promise<RoomAssignment>
    let newRoomAssignment: RoomAssignment
    if (contact) {
      if (roomAssignment.is_primary) {
        roomAssignment.primary_contact_hint = contact.contact_id
        // adding primary contact to first room
        const newRoomAssignment = Object.assign({}, roomAssignment) as RoomAssignment

        if (newRoomAssignment.rooms.length > 0) {
          if (!newRoomAssignment.rooms[0].room_contacts || newRoomAssignment.rooms[0].room_contacts.length === 0) {
            newRoomAssignment.rooms[0].room_contacts.push(
              new RoomContact(contact.contact_id, contact, '', this.siteService.siteDefaults.site_guest_attribute_template)
            )
            roomAssignment.rooms[0].room_contacts.push(
              new RoomContact(contact.contact_id, contact, '', this.siteService.siteDefaults.site_guest_attribute_template)
            )
          } else {
            newRoomAssignment.rooms[0].room_contacts[0].contact_id = contact.contact_id
            delete newRoomAssignment.rooms[0].room_contacts[0].contact
            roomAssignment.rooms[0].room_contacts[0].contact = contact
          }
          // let the backend know the attributes actaully have values
          for (const room of newRoomAssignment.rooms) {
            for (const room_contact of room.room_contacts) {
              for (const attr of room_contact.guest_attributes) {
                attr.attribute_has_value = attr.attribute_value || !attr.attribute_value
              }
            }
          }
        }
        promise = this.roomAssignmentService.save(roomAssignment)
      } else {
        newRoomAssignment = RoomAssignment.fromSecondaryRoomAssignment(roomAssignment, contact)
        promise = this.roomAssignmentService.save(newRoomAssignment)
      }
    } else {
      promise = Promise.resolve(new RoomAssignment)
    }
    let retRoomAssignment: RoomAssignment
    return promise.then(
      (res) => {
        retRoomAssignment = res
        // after saving, let's get the roomassignments for now

        return !skipRoomGet ? this.roomAssignmentService.getAll() : Promise.resolve([])
      }).then(
        (roomAssignments) => {
          // do something with room assignment
          return retRoomAssignment
        })
  }

  save(contact: Contact): Promise<Contact> {
    return this.contactService.save(contact)
      .then(
        (res) => {
          if (res) {
            return res
          }
        })
  }

  sendMessage(message: Message , outBoundData :Object): Promise<Message | {}> {
    return this.operatorService.sendMessage(this.currentStreamId, message ,outBoundData)
      .then((res) => {
        // do something on message sent
        // now we save the tags
        // we should get the message - but only the id returned
        // need to loop over tags
        const promises = []
        // be cause we are sending a message, it won't be on the queue yet let's add the id
        message.message_id = res.message_id
        let promise: Promise<Message | {}>
        if (message.tag && message.tag.length > 0) {
          for (const tag of message.tag) {
            promises.push(this.messageService.saveTag(tag, res.message_id))
          }
        }

        if (promises.length > 0) {
          const p = Promise.resolve()
          const final_promise = promises.reduce((prev, curr) => {
            // promises.reduce((prev, curr) => {
            return prev.then((result) => {
              return curr
            }).catch((err) => {
              console.log(err)
              return curr
            })
          }, p)
          if (final_promise) {
            promise = final_promise.then((result) => {
              return result
            })
          } else {
            promise = Promise.resolve({})
          }
        } else {
          promise = Promise.resolve({})
        }

        return promise
        // return this.messageService.saveTags(message.tag, res.message_id);
      })
      .then(
        (res) => {
          // now we get the message
          return this.messageService.getById(message.message_id)
        }).then((res: Message) => {
          // console.log(res);
          this.messageService.currentMessages = [res].concat(this.messageService.currentMessages)
          return res
        })
      .catch((err) => {
        console.log(err)
        return throwError(err).toPromise()
      })
  }
}
