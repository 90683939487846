import { throwError as observableThrowError, of as observableOf, Observable,Subject, Subscription, throwError, BehaviorSubject, of } from 'rxjs';

import { switchMap, map, delay, retry, catchError } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { OperatorLogin } from '../models/operator-login.model';
import { QueueService } from 'app/core/queue/queue.service';
import { Stream } from '../models/stream.model';
import { StreamResponse } from '../models/stream-response.model';
import { Message } from '../../shared/message.model';
import { Operator, OperatorApi } from '../models/operator.model';
import { UserService } from './user.service';
import { HttpErrorCodes, HttpSuccessCodes } from '../shared/enum';
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';

import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { SiteService } from './site.service';
import * as _ from 'lodash';
import { StreamsUpdated } from '../models/streams-updated.model'; 
import { StreamsUpdatedAllReq } from '../models/streams-updated-all-req.model';
import { Auth } from '../models/auth.model';
import { HttpClient } from '@angular/common/http';
import { TabService } from './tab.service';
import { takeUntil } from 'rxjs/operators';
import { AllReqStream } from '../models/all-req-stream.model';
import { PaginationService } from './pagination.service';
@Injectable()
export class OperatorService extends BaseDjangoService {
  // private unsubscribe$ = new Subject<void>();
  private unsubscribe$: Subject<void> = new Subject<void>()
  operatorLogin: OperatorLogin;
  channelId: string;
  keepLoginId: string;
  storageId: string;
  subRoute: string;
  streams: Stream[];
  allReqStreams : AllReqStream[];
  allCompletedReqStream :AllReqStream[]    // 10/12/24 - Vikas Gupta - A
  operators: OperatorApi[];
  streamsUpdated: EventEmitter<StreamsUpdated> = new EventEmitter();
  streamUpdatedAllReq :EventEmitter<StreamsUpdatedAllReq> = new EventEmitter();

  // Complete Tab - 10/12/24 - A
  streamUpdatedCompleteReq :EventEmitter<StreamsUpdatedAllReq> = new EventEmitter();

  operatorsUpdated: EventEmitter<OperatorApi[]> = new EventEmitter();
  loggedInEvent: EventEmitter<any> = new EventEmitter();
  _keepLogin = false;
  statusImpFlag : boolean = false;
  private subscriptions: Array<Subscription> = [];
  // private streamsSubscription: Subscription;
  private streamsSubscription: Subscription | undefined;
  callbyvar: string='';
  tabKey : string ;
  pagination :number;
  reassiginReq :string = ""
  constructor(private http: HttpClient,
    private storageService: StorageService,
    private queueService: QueueService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private tokenService: TokenService,
    private siteService: SiteService,
    private tabService: TabService,
    private paginationService :PaginationService
    ) {
    super(authenticationService);
    this.apiUrl += '/channel/operator';

    this.storageId = 'channel_id';
    this.keepLoginId = 'retry_logout';
    this.subRoute = 'stream';

  }

  set keepLogin(val: boolean) {
    this.storageService.set(this.keepLoginId, val);
  }

  get keepLogin() {
    return this.storageService.get(this.keepLoginId);
  }

  public sendUrgentMessage(operatorName: string, contactNumber: string, body: string) {
    return this.sendOperatorQuestion(operatorName, contactNumber, body, true);
  }

  public sendSeparateMessage(messageContent: string, MSISDN: string, fromnumber: string) {
    return this.sendSeparateMessageService(messageContent, MSISDN, fromnumber);
  }

  public sendNonUrgentMessage(operatorName: string, contactNumber: string, body: string) {
    return this.sendOperatorQuestion(operatorName, contactNumber, body, false);
  }

  public sendOperatorQuestion(operatorName: string, serviceNumber: string, message: string, isUrgent: boolean) {
    const urgentUrl = 'https://dashboard-api.jeeves.plus/admin/alerts/operator';
    const options = this.getOptions();
    const payload = {
      "ServiceNumber": serviceNumber,
      "Operator": operatorName,
      "Priority": isUrgent ? "urgent" : "normal",
      "Message": message
    };

    return this.http.post(urgentUrl, payload, options)
      .toPromise();
  }

  getoperatorinfo() {
    return this.storageService.get(this.storageId);
  }

  getChannelTokens(): OperatorLogin {
    return this.storageService.get(this.storageId) as OperatorLogin;
  }

  // getKeepLogin(): boolean {
  //   return this.storageService.get(this.keepLoginId) as boolean;
  // }

  login(): Promise<OperatorLogin> {
    const info = this.storageService.get(this.storageId);
    if (info == null) {
      let user_desc = '';
      let user_id: number;
      // need an empty object to log into the backend as an operator
      if (this.userService.current_user) {
        // user_desc = this.userService.current_user.first_name + ' ' + this.userService.current_user.last_name;
        user_desc = this.userService.current_user.username;
        user_id = this.userService.current_user.id;
      }
      const body: object = {
        endpoint_description: user_desc,
        endpoint_description_link: user_id,
        hotel_id: this.userService.current_user.hotel
      };
      const options = this.getOptions();

      return this.http.post(this.apiUrl, body, options)
        .toPromise()
        .then((res) => {
          const operator_info = res as OperatorLogin;

          this.storageService.set(this.storageId, operator_info);
          this.storageService.delete(this.keepLoginId);
          this.operatorLogin = operator_info;
          if (operator_info.site_id) {
            this.siteService.setSiteId(operator_info.site_id);
          }

          // start polling when operator has logged in
          // this.getStreams();

          // add back when we know the user is a manager and we actually need it
          // this.getAllOperators();

          return operator_info;
        }).catch(
          (err) => {
            // and now we rethrow the error
            throw err;
          });
    } else {
      // this.getStreams();

      // this.getAllOperators();

      return of(info).toPromise();
    }
  }

  private removeOperatorSession(): Observable<boolean> {
    // need an empty object to log into the backend as an operator
    const url = this.getStreamLogoutUrl();
    this.unsubscribe();
    const options = this.getOptions();

    return this.http.delete(url, options)
      .pipe(
        map((res) => {
          return true;
        }),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  logout(): Promise<boolean> {
    // need an empty object to log into the backend as an operator
    const url = this.getStreamLogoutUrl();

    this.queueService.stopPolling.next();
    this.unsubscribe();
    const options = this.getOptions();

    return this.http.delete(url, options)
      .toPromise()
      .then((res) => {
        this.storageService.delete(this.storageId);
        this.operatorLogin = null;
        return true;
      })
      .catch(
        (err) => {
          // need to prompt the user to re-login because we are unsubscribing before deleting the session
          // shouldn't log this out because it could leave the backend in a bad state

          // forcing logout as something could have happeneing on the server
          if (err.status === 404) {
            // we need to keep this info in the storage so we can delete the user on the next attempt
            // this.storageService.delete(this.storageId);
            this.storageService.set(this.keepLoginId, true);
            this.operatorLogin = null;
          }

          return observableThrowError(err).toPromise();
        });
  }

  logout$(): Observable<boolean> {
    // need an empty object to log into the backend as an operator
    const url = this.getStreamLogoutUrl();

    this.queueService.stopPolling.next();
    this.unsubscribe();
    const options = this.getOptions();

    let observable: Observable<any>;
    if (!url) {
      observable = of(true);
    } else {
      observable = this.http.delete(url, options);
    }

    return observable
      .pipe(
        map((res) => {
          this.storageService.delete(this.storageId);
          this.operatorLogin = null;
          return true;
        }),
        catchError(
          (err) => {
            // need to prompt the user to re-login because we are unsubscribing before deleting the session
            // shouldn't log this out because it could leave the backend in a bad state

            // forcing logout as something could have happeneing on the server
            if (err.status === 404) {
              // we need to keep this info in the storage so we can delete the user on the next attempt
              // this.storageService.delete(this.storageId);
              this.storageService.set(this.keepLoginId, true);
              this.operatorLogin = null;
            }

            return observableThrowError(err);
          })
      );
  }

  // logout$(): Observable<boolean> {
  //   // need an empty object to log into the backend as an operator
  //   const url = this.getStreamLogoutUrl();
  //   this.unsubscribe();
  //   const options = this.getOptions();
  //
  //   return this.http.delete(url, options)
  //     .pipe(
  //       retry(3),
  //       map((res) => {
  //         this.storageService.delete(this.storageId);
  //         this.operatorLogin = null;
  //         return true;
  //       }),
  //       catchError((err) => {
  //         // need to prompt the user to re-login because we are unsubscribing before deleting the session
  //         // shouldn't log this out because it could leave the backend in a bad state
  //
  //         // forcing logout as something could have happeneing on the server
  //         if (err.status === 404) {
  //           this.storageService.delete(this.storageId);
  //           this.operatorLogin = null;
  //         }
  //
  //         return observableThrowError(err).toPromise();
  //       })
  //     );
  // }

  getStreamById(id: string): Promise<StreamResponse[]> {
    const url = this.getStreamUrl() + '/' + id;
    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        return res as StreamResponse[];
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

  deleteStream(id: string): Promise<StreamResponse[] | boolean> {
    const url = this.getStreamUrl() + '/' + id;
    const options = this.getOptions();
    if (!options['observe']) {
      options['observe'] = 'response';
    }

    return this.http.delete(url, options)
      .pipe(
        map((response) => {
          delete this.streams;
          if (response && response['ok']) {
            return response['status'] == HttpSuccessCodes.DELETED;
          } else {
            return false;
          }
        })
      )
      .toPromise()
      .catch((err) => observableThrowError(err).toPromise());
  }

  sendMessage(id: string, message: Message , outBoundData : Object): Promise<Message> {
    const url = this.getStreamUrl() + '/' + id;
    const content = message.content[0];
    const tags = message.tags;
    content['mime-type'] = 'text/plain';
    content.encoding = 'utf8';
    content.lang = 'en';
    const obj: object = {
      content: content,
      tags:tags,
      outBoundData
    };
    const options = this.getOptions();

    // console.log('URL => '+ url);
    // console.log('CONTENT => '+ JSON.stringify(content));
    // console.log('CONTENT OBJECT => '+ obj);
    // console.log('OPTIONS => '+ JSON.stringify(options));

    return this.http.post(url, obj, options)
      .toPromise()
      .then(
        (res) => {
          // post to this api does not return anything at the moment
          // assume any 'success' response means message was sent
          // we should be getting the message_id, just transfrom it to a message object
          return res as Message;
        })
      .catch((err) => observableThrowError(err).toPromise());
  }

  public sendSeparateMessageService(messageContent: string, MSISDN: string, fromnumber: string) {

    const Url = "https://dashboard-api.jeeves.plus/admin/alerts/sendmessage";
    const options = this.getOptions();
    const payload = {
      "messageContent": messageContent,
      "MSISDN": MSISDN,
      "fromname": fromnumber
    };

    return this.http.post(Url, payload, options)
      .toPromise();
  }

  public checkPhoneNumber(MSISDN: string) {

    const Url = "https://dashboard-api.jeeves.plus/admin/alerts/checkmobilenumber";
    const options = this.getOptions();
    const payload = {
      "MSISDN": MSISDN
    };

    return this.http.post(Url, payload, options)
      .toPromise();
  }

  getAllOperators(): void {
    this.subscriptions.push(this.queueService.pull(this.apiUrl).subscribe((res) => {
      console.log(res);
      if (res != null) {
        const operators = res;
        if (operators || operators.length > 0) {
          this.operators = operators as OperatorApi[];

        } else {
          // this.streams
          if (!this.operators || this.operators == null) {
            this.operators = [];
          }
        }
      } else {
        this.operators = [];
      }

      this.operatorsUpdated.emit(this.operators);
    }));
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.hasOwnProperty('length') && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
    if (this.streamsSubscription != null) {
      this.streamsSubscription.unsubscribe();
      this.streamsSubscription = null;
    }
  }

  autoRefresh(): Observable<Auth> {
    this.unsubscribe();
    return this.tokenService.autoRefresh().pipe(
      map((auth: Auth) => {
        this.login();
        return auth;
      }));
  }

  private getStreamLogoutUrl(): string {
    if (!this.operatorLogin) {
      this.operatorLogin = this.storageService.get(this.storageId) as OperatorLogin;
    }

    if (!this.operatorLogin) {
      return;
    }

   
    // return this.apiUrl + '/' + this.operatorLogin.endpoint_id
   return `${this.apiUrl}/${this.operatorLogin.endpoint_id}?device_id=${this.operatorLogin.device_id}`
  }

  private getStreamUrl(): string {
    if (!this.operatorLogin) {
      this.operatorLogin = this.storageService.get(this.storageId) as OperatorLogin;
    }

    if (!this.operatorLogin) {
      return;
    }

    return this.apiUrl + '/' + this.operatorLogin.endpoint_id + '/' + this.subRoute;
  }
  private getStreamUrlForAllReq(): string {
    if (!this.operatorLogin) {
      this.operatorLogin = this.storageService.get(this.storageId) as OperatorLogin;
    }

    if (!this.operatorLogin) {
      return;
    }

    return this.apiUrl + '/' + this.operatorLogin.endpoint_id + '/' + this.subRoute +'?type=allrequest';
  }

  // Completed tab - 10/12/24 - Vikas Gupta - A

  private getStreamUrlForCompletedReq(): string {
    if (!this.operatorLogin) {
      this.operatorLogin = this.storageService.get(this.storageId) as OperatorLogin;
    }

    if (!this.operatorLogin) {
      return;
    }

    return this.apiUrl + '/' + this.operatorLogin.endpoint_id + '/' + this.subRoute +'?type=allCompletedRequest';
  }

  // Completed tab - 10/12/24 - Vikas Gupta - A

  callby(callby='')
  {
   this.callbyvar=callby;
  }
  reassiginReqCall(callby='')
  {
  
   this.reassiginReq=callby;
  }

subscriberdata(tab:any ,key?:any) {  
  
    const url = (tab === "My Request") ? this.getStreamUrl() : this.getStreamUrlForAllReq();
   if(tab === "My Request") {
    
    const url = this.getStreamUrl();
    this.streamsSubscription = this.queueService.pull(url)
    .pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        const retrying = this.queueService.retrying.getValue();
        // we want to
        if (retrying) {
          this.queueService.retrying.next(false);
        }
        // console.log(res);
        const oldStreams = _.cloneDeep(this.streams);
        this.streams = [];
        let hasUpdated = false;

        if (res != null) {
          let streams = res;
          if (streams && streams.length > 0) {
            // Case where streams is not empty
            if (!oldStreams || oldStreams.length === 0) {
              // If oldStreams is empty, just assign streams to this.streams

              
              this.streams = streams;
             // Indicate that this.streams has been updated
            } else {
              // If oldStreams is not empty, compare and update streams accordingly
              for (let stream of streams) {
                stream = Stream.toStream(stream);
                const idx = oldStreams.findIndex(s => s.stream_id === stream.stream_id);
                const ImpIdx = oldStreams.findIndex(s => s.stream_id === stream.stream_id && s.importantFlag === stream.importantFlag);
                
                if (idx === -1) {
                  // If stream not found in oldStreams, push it to this.streams
                  this.streams.push(stream);
                  hasUpdated = true;
                  
                } else if (ImpIdx === -1) {
                  // If stream found but importantFlag is different, update and mark as important
                  this.statusImpFlag = true;
                  
                  this.streams.push(stream);
                  hasUpdated = true;
                } 
                 // 25/02/25 - batchprocess - remove condtiotion for stream.contact === undefined
                else if (this.callbyvar !== '') {
                  // Special condition: if contact is undefined and callbyvar is not empty
                  this.statusImpFlag = true;
                  this.streams.push(stream);
                  hasUpdated = true;
                  setTimeout(() => {
                    this.callbyvar = '';
                  }, 1000);
                } else {
                  // Otherwise, update if version is different
                  if (stream && oldStreams[idx]) {
                    if (oldStreams[idx].version !== stream.version) {
                      this.streams.push(_.cloneDeep(stream));
                      hasUpdated = true;
                      
                    } else {
                      this.streams.push(_.cloneDeep(oldStreams[idx]));
                    }
                  }
                }
              }
              
              // Check if oldStreams.length is not equal to streams.length
              if (oldStreams.length !== streams.length) {
                this.streams = streams; // Assign streams to this.streams if lengths are different
                hasUpdated = true;
                
              }
            }
          } else {
            // Case where streams is empty
            if (!oldStreams || oldStreams.length === 0) {
            
              // If both streams and oldStreams are empty or null, set this.streams to empty array
              this.streams = [];
              hasUpdated = true; // Indicate that this.streams has been update
              
            } else {
              // If streams is empty but oldStreams is not, no action needed as per the current logic
              // You may add specific handling here if required
            }
          }
        } else {
          // Case where res is null
          this.streams = [];
          // Indicate that this.streams has been updated
        }
        

        // if (hasUpdated === true) {
        this.streamsUpdated.emit(new StreamsUpdated(this.streams, hasUpdated ,this.statusImpFlag));
        // }
      },
        (err) => {
          // something happened in the backend call, let's log the user out and let them know they are disconnected
          this.removeOperatorSession();
          // error is 404 - means user logged out from api
          if (typeof err === 'object' && err.status === HttpErrorCodes.SERVER_ERROR) {
            // we don't need to kick the user out here because the user is forced to logout from the popup

            this.unsubscribe();
            this.storageService.delete(this.storageId);
            this.operatorLogin = null;
            // if the operator gets kicked out of the backend, logout them out completely
            this.tokenService.remove();
            // this.router.navigate(['/login']);


            // workaround at the moment - if the error statis is 0, assume we need to login
          } else if ((typeof err === 'object' && err.message && +err.message === HttpErrorCodes.TOKEN_EXPIRING) ||
            (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED)) {
            // if the backend has booted us out then we refresh the token
            // if that doesn't work then.... log the user out
            this.unsubscribe();
            // don't need to logout of the backend yet
            // this.storageService.delete(this.storageId);
            // this.operatorLogin = null;
            this.tokenService.refresh()
              .then(
                (res) => {
                  // // in theory just need to redirect if something happens to the login
                  // this.logout().then(
                  //   (has_logged_out) => {
                  //   return this.login()
                  // }).then(
                  this.login().then(
                    (user_auth) => {
                      // leave this here incase we need it
                      // this.router.navigate(['/operator']);
                    }).catch(
                      (login_err) => {
                        console.log(login_err);
                        // something went wrong logging into the server, so we're bailing
                        this.storageService.delete(this.storageId);
                        this.operatorLogin = null;
                        this.tokenService.remove();
                        this.router.navigate(['/login']);
                      });
                }).catch(
                  (error) => {
                    // brute force a logout
                    console.log(error);
                    this.logout()
                      .then((res) => {
                        if (res) {
                          this.tokenService.remove();
                          this.router.navigate(['/login']);
                        }
                      })
                      .catch((logout_error) => {
                        this.tokenService.remove();
                        this.router.navigate(['/login']);
                      });
                  })

          }
        }
      );
   }

  
  if (tab === "All Request") {
    let url = this.getStreamUrlForAllReq();
    this.streamsSubscription = this.queueService.pull(url).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        const retrying = this.queueService.retrying.getValue();

        if (retrying) {
            this.queueService.retrying.next(false);
        }
        const oldStreams = _.cloneDeep(this.allReqStreams);
        this.allReqStreams = [];
        let hasUpdated = false;

        if (res != null) {
            let streams = res;
            if (streams && streams.length > 0) {
                if (!oldStreams || oldStreams.length === 0) {
                    this.allReqStreams = streams;
                    if (this.streams && this.streams.length > 0) {
                        hasUpdated = true;
                    }
                } else {
                    streams = streams as AllReqStream[];

                    // Check if lengths of oldStreams and streams are different
                    if (oldStreams.length !== streams.length) {
                        this.allReqStreams = streams;
                        hasUpdated = true;
                    } else {
                        for (let stream of streams) {
                            stream = AllReqStream.toStream(stream);
                            const idx = oldStreams.findIndex(s => s.stream_id === stream.stream_id);

                            // if stream is not found in oldStreams
                            if (idx === -1) {
                                this.allReqStreams.push(stream);
                                hasUpdated = true;
                            } else if (this.reassiginReq !== "") {
                                this.allReqStreams.push(stream);
                                hasUpdated = true;
                                setTimeout(() => {
                                    this.reassiginReq = "";
                                }, 1000);
                            } else {
                                if (stream && oldStreams[idx]) {
                                    if (oldStreams[idx].parent_endpoint_id !== stream.parent_endpoint_id) {
                                        // Handle the case where parent_endpoint_id is different
                                        console.log(`Different parent_endpoint_id found for stream_id ${stream.stream_id}`);
                                        this.allReqStreams.push(_.cloneDeep(stream));
                                        hasUpdated = true;
                                    } else if (oldStreams[idx].version !== stream.version) {
                                        this.allReqStreams.push(_.cloneDeep(stream));
                                        hasUpdated = true;
                                    } else {
                                        this.allReqStreams.push(_.cloneDeep(oldStreams[idx]));
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                // Handle case when res is empty but oldStreams is not
                if (!oldStreams || oldStreams == null) {
                    this.allReqStreams = [];
                }
            }
        } else {
            this.allReqStreams = [];
        }

        // Emit the updated streams if there were any changes
        this.streamUpdatedAllReq.emit(new StreamsUpdatedAllReq(this.allReqStreams, hasUpdated, this.statusImpFlag));
    },
    (err) => {
        // Handle errors
        this.removeOperatorSession();
        if (typeof err === 'object' && err.status === HttpErrorCodes.SERVER_ERROR) {
            this.unsubscribe();
            this.storageService.delete(this.storageId);
            this.operatorLogin = null;
            this.tokenService.remove();
        } else if ((typeof err === 'object' && err.message && +err.message === HttpErrorCodes.TOKEN_EXPIRING) ||
            (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED)) {
            this.unsubscribe();
            this.tokenService.refresh()
                .then((res) => {
                    this.login().then((user_auth) => {}).catch((login_err) => {
                        console.log(login_err);
                        this.storageService.delete(this.storageId);
                        this.operatorLogin = null;
                        this.tokenService.remove();
                        this.router.navigate(['/login']);
                    });
                }).catch((error) => {
                    console.log(error);
                    this.logout()
                        .then((res) => {
                            if (res) {
                                this.tokenService.remove();
                                this.router.navigate(['/login']);
                            }
                        })
                        .catch((logout_error) => {
                            this.tokenService.remove();
                            this.router.navigate(['/login']);
                        });
                });
        }
    });
}


// Completed tab - 10/12/24 - Vikas Gupta - A 


if (tab === "Completed Request") {
  let url = this.getStreamUrlForCompletedReq();
  this.streamsSubscription = this.queueService.pull(url).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      const retrying = this.queueService.retrying.getValue();

      if (retrying) {
          this.queueService.retrying.next(false);
      }
      // allCompletedReqStream
      const oldStreams = _.cloneDeep(this.allCompletedReqStream);
      this.allCompletedReqStream = [];
      let hasUpdated = false;

      if (res != null) {
          let streams = res;
          if (streams && streams.length > 0) {
              if (!oldStreams || oldStreams.length === 0) {
                  this.allCompletedReqStream = streams;
                  if (this.streams && this.streams.length > 0) {
                      hasUpdated = true;
                  }
              } else {
                  streams = streams as AllReqStream[];

                  // Check if lengths of oldStreams and streams are different
                  if (oldStreams.length !== streams.length) {
                      this.allCompletedReqStream = streams;
                      hasUpdated = true;
                  } else {
                      for (let stream of streams) {
                          stream = AllReqStream.toStream(stream);
                          const idx = oldStreams.findIndex(s => s.stream_id === stream.stream_id);

                          // if stream is not found in oldStreams
                          if (idx === -1) {
                              this.allCompletedReqStream.push(stream);
                              hasUpdated = true;
                          } else if (this.reassiginReq !== "") {
                              this.allCompletedReqStream.push(stream);
                              hasUpdated = true;
                              setTimeout(() => {
                                  this.reassiginReq = "";
                              }, 1000);
                          } else {
                              if (stream && oldStreams[idx]) {
                                  if (oldStreams[idx].parent_endpoint_id !== stream.parent_endpoint_id) {
                                      // Handle the case where parent_endpoint_id is different
                                      console.log(`Different parent_endpoint_id found for stream_id ${stream.stream_id}`);
                                      this.allCompletedReqStream.push(_.cloneDeep(stream));
                                      hasUpdated = true;
                                  } else if (oldStreams[idx].version !== stream.version) {
                                      this.allCompletedReqStream.push(_.cloneDeep(stream));
                                      hasUpdated = true;
                                  } else {
                                      this.allCompletedReqStream.push(_.cloneDeep(oldStreams[idx]));
                                  }
                              }
                          }
                      }
                  }
              }
          } else {
              // Handle case when res is empty but oldStreams is not
              if (!oldStreams || oldStreams == null) {
                  this.allCompletedReqStream = [];
              }
          }
      } else {
          this.allCompletedReqStream = [];
      }

      // Emit the updated streams if there were any changes
      this.streamUpdatedCompleteReq.emit(new StreamsUpdatedAllReq(this.allCompletedReqStream, hasUpdated, this.statusImpFlag));
  },
  (err) => {
      // Handle errors
      this.removeOperatorSession();
      if (typeof err === 'object' && err.status === HttpErrorCodes.SERVER_ERROR) {
          this.unsubscribe();
          this.storageService.delete(this.storageId);
          this.operatorLogin = null;
          this.tokenService.remove();
      } else if ((typeof err === 'object' && err.message && +err.message === HttpErrorCodes.TOKEN_EXPIRING) ||
          (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED)) {
          this.unsubscribe();
          this.tokenService.refresh()
              .then((res) => {
                  this.login().then((user_auth) => {}).catch((login_err) => {
                      console.log(login_err);
                      this.storageService.delete(this.storageId);
                      this.operatorLogin = null;
                      this.tokenService.remove();
                      this.router.navigate(['/login']);
                  });
              }).catch((error) => {
                  console.log(error);
                  this.logout()
                      .then((res) => {
                          if (res) {
                              this.tokenService.remove();
                              this.router.navigate(['/login']);
                          }
                      })
                      .catch((logout_error) => {
                          this.tokenService.remove();
                          this.router.navigate(['/login']);
                      });
              });
      }
  });
}


// Completed tab - 10/12/24 - Vikas Gupta - A 




}

private unsubscribeFromStreams(): void {  
  if (this.streamsSubscription) {
  this.unsubscribe$.next();
    // this.unsubscribe$.complete();
    // this.streamsSubscription.unsubscribe();
    // this.streamsSubscription = null;

  }
}
 getStreams(): void {

    // return this.http.get(this.apiUrl + '/' + this.subRoute)
    // unsubscribe first
    // this.unsubscribe()
    
    if (this.streamsSubscription != null) {
      return;
    }
    // if (this.streamsSubscription != null) {
    //   this.streamsSubscription.unsubscribe();
    // }
  
    this.tabService.getTabKey().subscribe(key => {
      // if (this.tabKey !== key) {        
        // Unsubscribe from the current subscription when the tab changes
        //  this.unsubscribeFromStreams();
        //  console.log("Unsubscribe DOne");
        // Subscribe to the new tab's stream
      // }
      
      // this.subscriberdata(key);
      // this.tabKey = key;
      // console.log("this.tabKey-->",this.tabKey);
      if(key == "My Request") {
        if (this.streamsSubscription) {
          this.unsubscribe$.next();
        }
        this.subscriberdata("My Request")
      }
      else if(key == "All Request") {
        if (this.streamsSubscription) {
          this.unsubscribe$.next();
        }
        this.subscriberdata("All Request")
        // this.paginationService.getPagination().subscribe(key=>{
        //   this.pagination = key;
          
        //   if (this.streamsSubscription) {
        //     this.unsubscribe$.next();
        //   }
          
        // })
      }
      // Completed tab - 10/12/24 - Vikas Gupta - A

      else if(key == "Completed Request") {
        if (this.streamsSubscription) {
          this.unsubscribe$.next();
        }
        this.subscriberdata("Completed Request")
      }
      
      // Completed tab - 10/12/24 - Vikas Gupta - A

     
    });
 

    
  
 }

 reAssign (stream_id){
    const payload = {
      parent_endpoit_id : this.operatorLogin.endpoint_id,
      stream_id : stream_id
    }
    const options = this.getOptions();
    const Url  = this.apiUrl+"/"+"reassign"
    return this.http.post(Url, payload, options).toPromise();
    
    
 }



//  Completed tab - 10/12/24 - Vikas Gupta - A 

reOpen (stream_id){
  const payload = {
    parent_endpoit_id : this.operatorLogin.endpoint_id,
    stream_id : stream_id
  }
  const options = this.getOptions();
  const Url  = this.apiUrl+"/"+"reopen"
  return this.http.post(Url, payload, options).toPromise();
  
  
}



//  Completed tab - 10/12/24 - Vikas Gupta - A 



  public updateImportantFlagStatus (data : any){
    const Url = `${this.apiUrl}/stream`
    const options = this.getOptions();
    const payload = data
    return this.http.post(Url, payload, options).toPromise();
  }

  ngOnDestroy(): void {
    // Emit to unsubscribe from all subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
