import { Component, Input, OnInit, Renderer2, ViewChild, AfterViewChecked, ElementRef, ChangeDetectorRef, OnChanges } from '@angular/core';
import anchorme from 'anchorme'
import { Message } from '../shared/message.model';
import { MessageService } from '../core/services/message.service';
import { ConstantsService } from '../core/services/constants.service';
import { OperatorService } from '../core/services/operator.service';
import { OperatorViewService } from '../operator/operator-view.service';
import { ContactService } from '../core/services/contact.service';
import { Contact } from '../core/models/contact.model';
import { ContactExport } from '../core/shared/enum';
import { UserService } from '../core/services/user.service';
import { User } from '../shared/user.model';
import { SelectOption } from '../core/models/select-option.model';
import { BaseComponent } from '../shared/base/base.component';
import { RoomAssignmentService } from '../core/services/room-assignment.service';
import { Room, RoomAssignment } from '../core/models/room-assignment.model'
import { SiteService } from '../core/services/site.service';
import { Content, SiteBulkMessageTemplate } from '../core/models/site.model';
import { TagService } from '../core/services/tag.service';
import * as _ from 'lodash';
import { StreamsUpdated } from '../core/models/streams-updated.model';
import { HotelService } from '../core/services/hotel.service';
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { Hotel } from "../core/models/hotel.model";
import { ToastyService } from "ng2-toasty";
import { FilterByPipe } from "../core/pipes/filter-by.pipe";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../core/services/authentication.service';
import { environment as env } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BlockMessageComponent } from 'app/components/block-message/block-message.component';
// 27/02/25 - Added matdialog component and blockmessage component

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css']
})
export class MessageDetailsComponent extends BaseComponent implements OnInit {
  languages: SelectOption[];
  priorities: SelectOption[];
  loading: boolean;
  message: Message = new Message();
  user: User;
  returnedMessageIds: string[] = [];
  messageIds: string[] = [];
  stream_id: string;
  stream_version: string;
  contact: Contact;
  contactNumber: string;
  newTag: string;
  keyCodes = [9, 13];
  operatorMessageDir = 'out';
  operatorContentIdx = 0;
  guestDetails: RoomAssignment;
  roomNumbers = '';
  @Input() messages: Message[];
  gettingMessage = false;
  completingRequest = false;
  operatorResponses: SiteBulkMessageTemplate[];
  messageHistory: Message[];
  fullMessageHistory: Message[];
  currentAndPastMessageHistory: Message[];
  historyPageSize = 10
  users: User[];
  defaultTags: string[];
  tags: string[];
  isSending = false;
  autoTranslate: boolean;
  stream_updated = false;
  stream_history_updated = false;
  sending_messages = false;
  subscriptions: Subscription[] = [];
  currentHotel: Hotel;
  _content: string = '';

  searchTemplate = '';
  searchTemplateData: SiteBulkMessageTemplate[];
  showMessage = true;
  rooms: Room[] = [];
  textToSpeech: boolean;

  // stream created date 25/02/25 - bactchprocess
  streamCreatedAT:any;
  firstcurrentsessionmsg:any;
  stream_length:any
  lastEvaluatedKey:any='';
 // stream created date 25/02/25
  currentOrganization: Hotel;
  apiUrl: string;
  _authenticationService: AuthenticationService;
  maskContactObject: any = { };
  contactNumberWithMask: string;
  subscriptionEnabled:boolean = false; // 24/03/25 passed subscriptionEnabled when clicking on contact

  @ViewChild('tagAutocomplete') public tagAutocomplete: any;
  @ViewChild('templateDropdown') public templateDropdown: any;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  forOutboundMessage: Message[];

  constructor(
    private messageService: MessageService, private constantsService: ConstantsService,
    private operatorViewService: OperatorViewService, private operatorService: OperatorService,
    contactService: ContactService, private userService: UserService, private roomAssignmentService: RoomAssignmentService,
    private siteService: SiteService, private tagService: TagService, private renderer: Renderer2, private hotelService: HotelService,
    private toastyService: ToastyService, private filterByPipe: FilterByPipe, private cdRef: ChangeDetectorRef,
    authenticationService: AuthenticationService,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog, // 27/02/25 - for block send message popup

  ) {
    super(contactService);
    this.apiUrl = env.api;
    this._authenticationService = authenticationService;
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  set content(val: any) {
    this._content = val;
  }

  enterPressedHandler() {
    if (this.user.enter_to_send_message) {
      this.message.content[0].data = this.message.content[0].data.trim()
      this.sendMessage();
    }
  }

  formatUrlLinks(text: string): string {
    const options = {
      attributes: {
        target: '_blank'
      },
    }
    return anchorme({
      input: text,
      options: options
    })
  }

  /**
   * since there are only 2 indexes, we only ever show either the 0 or 1 index
   * if the direction is 'out' then show the '0' index as the translated (since this is for the operator)
   * if the direction is 'in' then show the '1' index as the translated (since this if for the customer and the
   *     operator should see the translated text (since it could be in another language)
   *
   * @param msg
   * @param val
   */
  setMessageTranslatedIdx(msg: Message, val: any) {
    msg.transIndex = val;
    msg.origIndex = val == 0 ? 1 : 0;
  }

  // addTag(event: any): void {
    addTag(value: any): void {
      if (value && value.trim()) {
        console.log(this.message.tags.indexOf(value),"testttttt");
        
        if(this.message.tags.indexOf(value)==-1)
          {
            this.message.tags.push(value);
          }
      }
      this.newTag = '';
      console.log(this.message.tags,"tagsssssssss");
      if (this.tagAutocomplete) {
        this.tagAutocomplete.clearValue(); // Clear the Kendo component's value
        this.tagAutocomplete.reset(); // Reset the state if required
      }
    }

  filterTemplates(): void {
    this.searchTemplateData = this.operatorResponses
      .filter(or => or.message.data.toLowerCase().indexOf(this.searchTemplate.toLowerCase()) !== -1);
  }

  removeTag(idx: number): void {
    if (this.message && this.message.tags && this.message.tags.length > idx) {
      this.message.tags.splice(idx, 1);
    }
  }

  getLanguages(): void {
    this.constantsService.get().then((choices) => {
      this.choices = choices;
      this.languages = choices.languages;
      this.priorities = choices.priorities;
    }).catch((error) => {
      this.languages = [];
      console.log(error);
    });
  }

  // sendMessage(): void {
  async sendMessage() {
    console.log("cccccccccc",this.contact);

    let contact = this.contact;
    // condition to block message when customer credits is 0 and subscriptionEnabled true - 14.02/25  
    if((contact.credits == 0 || contact.credits == undefined) && this.subscriptionEnabled)
    {      
       const dialogRef = this.dialog.open(BlockMessageComponent, {
                  hasBackdrop: true,
                });
      
                dialogRef.afterClosed().subscribe(result => {});
      return;
    }
    // 27/02/25 if customer credits is 0 then dont allow operator to send message 

    
    // if we don't have any text or a stream to send it to, don't send the message
    if (!this.message.content[0].data || !this.stream_id) {
      return;
    }

    var isAudioEnabled = document.getElementById("audioCheked") as HTMLInputElement;
    if(isAudioEnabled && isAudioEnabled.checked) {
      this.isSending = true;
      console.log('isAudioEnabled => ' +isAudioEnabled.value);

        const textMessage = this.message.content[0].data;
        const Url = this.apiUrl+"/textspeech/makeaudio";
        const options = this.getOptions();
        const payload = {
          "textMessage": textMessage,
          "streamId": this.stream_id,
          "randomNumber": Math.random()
        };

        await this.http.post(Url, payload, options)
          .toPromise()
          .then((result) => {
            var audioLink = JSON.parse(JSON.stringify(result));
            // const string2 = audioLink.Location;
            const string2 = audioLink.shortURL;
            console.log("url",this.message.content[0].data.concat('  Voice File: '+string2));
            
            this.message.content[0].data = this.message.content[0].data.concat('  Voice File: '+string2);
        });
    }
    console.log('Audio Link after'+ this.message.content[0].data);
    // get all in messages for getting latest in message channel - 01/04/2025
    let allInMessages = this.messages.filter(message => message.direction == "in");
    // console.log("latestmsg",allInMessages[allInMessages.length - 1],this.messages[this.messages.length - 1]);
    
    this.isSending = true;
    this.message = this.transformTags(this.message, true);
    if (this.messages && this.messages.length > 0) {
      // const len = this.messages.length - 1;
      // // get the previous pi value
      if (this.autoTranslate && this.messages.length > 0) {
        console.log(this.messages[0].content[0]);
        // since the messages are now from top to bottom, we need to get the last message in the list
        this.message.content[0].pi = allInMessages[allInMessages.length - 1].content[0].pi || Content.DEFAULT_PI_VAL;
      } else {
        // 01/04/2025
        // console.log("autotranslate");
        var parsedata = JSON.parse(allInMessages[allInMessages.length - 1].content[0].pi);
        parsedata.origLang ='en';
        this.message.content[0].pi = JSON.stringify(parsedata)
        // console.log("messggg",this.message.content[0].pi);
      }
    }

    if (this.user) {
      console.log("exist user");
      
      this.message.posted_by = this.user.id.toString();
    }

    // if we have a stream id, we can send a message
   
    let outBoundData :object;
    
    // if (this.forOutboundMessage && this.forOutboundMessage.length > 0) {
    //     const lastMessage = this.forOutboundMessage[this.forOutboundMessage.length - 1];
    
    //     if (lastMessage && lastMessage.message_id && lastMessage.source_channel_subscriber && lastMessage.content) {
    //         const lastMessageId = lastMessage.message_id;
    //         const msisdn = lastMessage.source_channel_subscriber.resource_identifier;
    //         const pi = lastMessage.content[0].pi;
    
    //         outBoundData = {
    //             direction: lastMessage.direction,
    //             message_id: lastMessageId,
    //             pi,
    //             msisdn
    //         };
    
    //         console.log("outbound", outBoundData);
    
    //     } else {
    //         console.error("Incomplete lastMessage object:", lastMessage);
    //     }
    // } else {
    //     console.error("No messages available or messages array is undefined.");
    // }
    const operatorData = JSON.parse( localStorage.getItem("channel_id"))
    const site_id = operatorData.site_id
    const posted_by = operatorData.endpoint_description_link
    const resource_ident = this.contactNumber || this.contact.channel_subscription.resource_identifier;
    outBoundData = {
       site_id,
       msisdn : resource_ident,
       posted_by   
    }


    this.operatorViewService.sendMessage(this.message , outBoundData)
      .then((res) => {
        // do something on message sent
        if (res) {
          this.message = new Message();
          // now we need to go and get the message that was just sent
          // 27/02/25 - staticly updating credits when message sent 
          if(this.subscriptionEnabled && (contact.credits as number) > 0){
            contact.credits = (contact.credits as number) - 1;
          }
          // this.messages = this.messageService.currentMessages;
        }
        this.isSending = false;
        // now scrolling to the bottom if the user has scrolled up
        this.scrollToBottom();
      })
      .catch((err) => {
        console.log(err);
        this.isSending = false;
        throw err;
      });
  }

  subscribeToCurrentContact(): void {
    this.contactService.currentContactUpdated.subscribe(
      (key: ContactExport) => {
        this.contactNumber = null;
        this.contact = null;
        this.messageHistory = [];
        this.fullMessageHistory = []
        this.currentAndPastMessageHistory = []
        
        if (key != null) {
          if (key === ContactExport.contact) {
            this.contact = this.contactService.currentContact;
            if (!this.contact.last_name) {
              this.contactNumber = this.contact.channel_subscription.resource_identifier;
              this.contactNumberWithMask = this.contact.channel_subscription.resource_identifier.replace(/^.{6}/g, '******');
            }
          } else {
            this.contactNumber = this.contactService.currentNumber;
          }
          // 25/02/25 - batchprocess - comment
          // if (!this.stream_history_updated) {
          //   this.getMessageHistory();
          // }
        }
        // this.loadFullHistory();

        // this.contact = contact;
      }, (err) => {
        throw err;
      });
  }

  subscribeToCurrentRoomAssignment(): void {
    this.roomAssignmentService.roomAssignmentUpdated.subscribe(
      (roomAssignment: RoomAssignment) => {
        this.roomNumbers = '';
        if (roomAssignment != null) {
          this.guestDetails = roomAssignment;
          if (this.guestDetails.rooms) {
            for (const room of this.guestDetails.rooms) {
              this.roomNumbers += room.room_number + ', ';
            }
            this.roomNumbers = this.roomNumbers.substring(0, this.roomNumbers.length - ', '.length);
          }
        }
        // this.contact = contact;
      }, (err) => {
        throw err;
      });
  }

  subscribeToUserUpdate(): void {
    if (this.userService.current_user) {
      // get whatever is there as this component might be initialized after we get the user
      this.user = this.userService.current_user;
    }
    this.userService.getCurrentUserUpdated().subscribe(
      (user: User) => {
        this.user = user;
      }, (err) => {
        throw err;
      });
  }

  // TODO: test the 2 functions below as there could be a race condition that occurs
  subscribeToStreamUpdated(): void {
    this.operatorService.streamsUpdated.subscribe(
      (streamsUpdated: StreamsUpdated) => {
        if (this.stream_id != null && streamsUpdated.hasUpdated) {
          const streamItems = streamsUpdated.streams;
          for (const stream of streamItems) {
            if (stream.stream_id === this.stream_id && stream.version !== this.stream_version) {
               // 25/02/25 - batchprocess - if stream update then passing type autoupdate
               this.getMessages('autoupdate');
            }
          }
        }
      }, (err) => {
        throw err;
      });
  }

  subscribeToShowMessageUpdated(): void {
    this.operatorViewService.showMessageUpdated.subscribe(
      (showMessage: boolean) => {
        this.showMessage = showMessage;
      }, (err) => {
        throw err;
      });
  }

  subscribeToMessageId(): void {
    this.operatorViewService.currentStreamIdUpdated.subscribe(
      (value: any) => {
        if (value != null && value !== '') {
          this.subscriptionEnabled = value.subscriptionEnabled
          this.gettingMessage = true;
          this.loading = true;
          this.messages = [];
          this.messageIds = [];
          this.messageHistory = [];
          this.returnedMessageIds = [];
          this.stream_id = value.stream_id;
          this.stream_version = value.version;
          this.stream_updated = true;
          // 25/02/25 - batchprocess - getting current session date
          this.firstcurrentsessionmsg = value.create_datenew
          //  25/02/25 - batchprocess - getting current session message list length
          this.stream_length = value.message_id_list_length 

          // getting messages
          this.getMessages();
        } else if (value === '' || value == null) {
          this.stream_updated = false;
          this.gettingMessage = false;
          this.loading = false;
          this.messages = [];
          this.messageIds = [];
          this.messageHistory = [];
          this.returnedMessageIds = [];
          this.stream_id = '';
          this.stream_version = '';
          // 24/02/25 - batchprocess - if respose is null or empty string
          this.firstcurrentsessionmsg = ''
          this.stream_length=0;
        }
        if (this.cdRef.hasOwnProperty('destroyed') && !this.cdRef['destroyed']) {
          this.cdRef.detectChanges();
        }
      }, (err) => {
        throw err;
      });
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$.subscribe(hotel => {
        this.currentHotel = hotel;
      }, (err) => {
        throw err;
      })
    );
  }



  // 25/02/25 - batchprocess - type argument
  private getMessages(type:any='') {
    // this.loading = true;

    console.log(this.contact.channel_subscription);



    if(type=='')
    {
      // 24/02/25 - barchprocess - just for getting current session start date
      this.messageService.getById(this.firstcurrentsessionmsg).then((msg:any) =>{
        this.streamCreatedAT = msg.create_date
     })

    
    }

    // 25/02/25 - batchprocess - function to get message in batch

    const resource_ident = this.contact.channel_subscription.resource_identifier;
    this.messageService.getByParamLastId(resource_ident,type)
    .then((stream_response) => {

      if (!stream_response || !stream_response.result) {
        throw new Error("Invalid response from API");
      }
      const promises = stream_response.result;
      if (promises.length === 0) {
        this.loading = false;
        return;
      }
      const p = Promise.resolve();
      const finalPromise = promises.reduce((prev: any, curr: any) => {
        return prev
          .then(() => curr)
          .then((res: any) => {
            if (!res) {
              console.warn("Skipping empty response.");
              return;
            }
            // check message is exists 24/02/25 - batchprocess
            const exists = this.messages.some((msg) => msg.message_id === res.message_id);
            // if messaaage is newly created then tranformtag 24/02/25 - batchprocess
            if (!exists) {
              const message = this.transformTags(res);

              if (message && !message.posted_by) {
                message.posted_by = this.user.id.toString();
              }

            this.messages.push(message);
              this.cdRef.detectChanges();
              this.scrollToBottom();
            } else {
              console.log(`Duplicate message skipped: ${res.message_id}`);
            }
          })
          .catch((err) => {
            console.error("Error processing message:", err);
          });
      }, p);

      finalPromise
        .then(() => {
          console.log("Final messages:", this.messages);
          this.loading = false;
          this.scrollToBottom();
          if (stream_response.lastEvaluatedKey) {
            if(type !='autoupdate')
            {
              localStorage.setItem("lastEvaluatedKey", JSON.stringify(stream_response.lastEvaluatedKey));
              this.lastEvaluatedKey = stream_response.lastEvaluatedKey;

            }
          }
        })
        .catch((err) => {
          console.error("Error in message processing pipeline:", err);
          this.loading = false;
        });
    })
    .catch((err) => {
      console.error("Error fetching messages:", err);
      this.loading = false;
    });
  }

  updateRoomContacts(event) {
    this.rooms = event
  }

  // delete after creating the proper function
  populateMessage(response: SiteBulkMessageTemplate): void {
    this.message.content[0].data = response.message.data;
    // console.log(this.templateDropdown);
    // this.templateDropdownOpen = false;

    // can't think of a better way to do this
    $(this.templateDropdown.nativeElement).hide();
  }

  // subscribeToSiteDefaults(): void {
  getSiteDefaults(): void {
    const login_details = this.operatorService.getChannelTokens();
    // the site id should exist if it doedsn't then something is very wrong
    this.siteService.setSiteId(login_details.site_id);
    if (this.siteService.siteDefaults) {
      this.operatorResponses = this.siteService.siteDefaults.site_official_responses;
      this.searchTemplateData = this.operatorResponses;
    } else {
      this.siteService.getSiteById()
        .then((res) => {
          this.operatorResponses = res.site_official_responses;
          this.searchTemplateData = this.operatorResponses;
        });
    }
  }

  getUsers(): void {
    if (this.userService.users) {
      this.users = this.userService.users;
    } else {
      this.userService.getAll()
        .then((users) => {
          this.users = users;
        });
    }
  }

  getTags(): void {
    this.defaultTags = this.tagService.getTagList();
    this.tags = _.cloneDeep(this.defaultTags);
  }

  handleTagFilter(value: any) {
    this.tags = this.defaultTags.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  scrollToBottom(): void {
    const el = this.myScrollContainer.nativeElement;
    if (el.scrollHeight != el.clientHeight) {
      el.scrollTop = el.scrollHeight - el.clientHeight;
    }
  }

  getMaskContact(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/mask";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((maskContactObject) => {
              this.maskContactObject = JSON.parse(JSON.stringify(maskContactObject));  
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  getCurrentOrganization(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            // const Url = this.apiUrl+"/shopify";
            const Url = this.apiUrl+"/textspeech";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };
            return this.http.post(Url, payload, options)
              .toPromise()
              .then((result) => {
                this.textToSpeech = JSON.parse(JSON.stringify(result));
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  ngOnInit() {
    this.getLanguages();
    this.loading = false;

    this.subscribeToMessageId();
    this.subscribeToCurrentContact();
    this.subscribeToStreamUpdated();
    this.subscribeToUserUpdate();
    this.subscribeToCurrentRoomAssignment();
    this.subscribeToShowMessageUpdated();
    this.subscribeToCurrentHotel();
    this.getSiteDefaults();
    this.getCurrentOrganization();
    if (this.siteService.siteDefaults) {
      this.operatorResponses = this.siteService.siteDefaults.site_official_responses;
      this.searchTemplateData = this.operatorResponses;
    }
    this.getUsers();
    this.getTags();
    this.getMaskContact();
    this.message = new Message();
    this.autoTranslate = true;
  }




// 24/02/25 - batchprocess - function to load new message in batch

async loadMessage() {
  try {
    this.loading = true;

    var lastid= this.messages[0].message_id;
    document.getElementById(lastid).scrollIntoView({ behavior: 'smooth' });
    console.log("Loading messages...");

    if (!this.contact) {
    this.loading = false;
      console.error("No contact selected.");
      return;
    }

    const resource_ident = this.contact.channel_subscription.resource_identifier;    
      if (!resource_ident) {
    this.loading = false;

      console.error("Invalid contact resource identifier.");
      return;
    }


    // Fetch messages from the service
    const res: any = await this.messageService.getByParamLastId(resource_ident, "load");

    if (!res || !res.result) {
      console.error("Invalid response from API", res);
      this.loading = false;
      return;
    }

    const promises = res.result;
    console.log("Messages fetched:", promises);

    if (promises.length === 0) {
      console.log("No new messages to load.");
      this.loading = false;
      return;
    }

    // Process messages sequentially using reduce
    await promises.reduce((prevPromise, currMessage) => {
      return prevPromise
        .then(() => Promise.resolve(currMessage)) // Ensure it's a resolved promise
        .then((res: any) => {
          if (!res) {
            console.warn("Skipping empty response.");
            return;
          }

          console.log("Processing message:", res);

          const exists = this.messages.some((msg) => msg.message_id === res.message_id);
          if (!exists) {
            const message = this.transformTags(res);

            if (message && !message.posted_by) {
              message.posted_by = this.user.id.toString();
            }

            this.messages.unshift(message);
            console.log(message.content,"contentyyttt");
            
            // this.cdRef.detectChanges();
            // this.scrollToUp();
          } else {
            console.log(`Duplicate message skipped: ${res.message_id}`);
          }
        })
        .catch((err) => {
          console.error("Error processing message:", err);
        });
    }, Promise.resolve());

    console.log("All messages processed:", this.messages);

    // Store last evaluated key for pagination
    // if (res.lastEvaluatedKey) {
      localStorage.setItem("lastEvaluatedKey", JSON.stringify(res.lastEvaluatedKey));
      this.lastEvaluatedKey = res.lastEvaluatedKey;
    // }

  } catch (error) {
    console.error("Error loading messages:", error);
  } finally {
    this.loading = false;
    // this.scrollToUp(); // Ensure UI updates even on failure
  }
}

// 04/03/25
clearMessages() {
  console.log("Clearing");
  this.messages = []; // Empty the messages array
  this.lastEvaluatedKey = null; // Clear  
  this.loading = false
}


}
