import { Component, OnInit} from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { ContactService } from '../../core/services/contact.service';
import { MatDialogRef } from '@angular/material/dialog';

// 27/02/25 - Block message dialog popup - if customer credits is 0
@Component({
  selector: 'app-block-message',
  templateUrl: './block-message.component.html',
})
export class BlockMessageComponent  extends BaseComponent implements OnInit {
constructor(
    public contactService: ContactService,
    public dialogRef: MatDialogRef<BlockMessageComponent>,
  ){
    super(contactService);
  }

  synchronize(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }
}
