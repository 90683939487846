import { Contact } from "./contact.model";

export class SourceChannelSubscriber {
  resource_identifier?: string;
  channel_identifier?: string;
}

export class Stream {
  stream_id?: string;
  version?: string;
  parent_endpoint_id?: string;
  route_instance_key?: string;
  source_channel_subscriber?: SourceChannelSubscriber;
  message_id_list_length?: number;
  // all the 'dates' below are time since epoc in milliseconds
  create_date?: number;
  updated_at?: number;
  last_message_in_date?: number;
  last_message_out_date?: number;
  contact?: Contact;
  importantFlag?:boolean;
  create_datenew?:any; // 25/02/25 - betchprocess - for  requested date
  static dateObjectList: string[] = ['checkin_date', 'last_update_date', 'last_message_in_date'];
  subscriptionEnabled?: boolean; // 27/02/25 passed subscriptionEnabled
  AgeCheck?: boolean; // 05/03/25 passed AgeCheck



  static toStream(obj: any): Stream {
    const keys = Object.keys(obj);
    const stream = new Stream();
    for (const key of keys) {
      if ((stream[key] instanceof Date && typeof obj[key] === 'string') ||
        this.dateObjectList.indexOf(key) > -1) {
        stream[key] = new Date(obj[key]);
      } else {
        stream[key] = obj[key];
      }
    }
    return stream;
  }
}
