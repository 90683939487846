import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageDetailsComponent } from './message-details/message-details.component';
import { OperatorComponent } from './operator/operator.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { GuestPanelModule } from './guest-panel/guest-panel.module';
import { ManagerComponent } from './manager/manager.component';
import { MessageQueueModule } from './message-queue/message-queue.module';
import { InterceptorModule } from './core/interceptor/interceptor.module';
import { StartupModule } from './core/startup/startup.module';
import { OperatorViewService } from './operator/operator-view.service';
import { RegisterModule } from './register/register.module';
import { ProfileComponent } from './profile/profile.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastyModule } from 'ng2-toasty';
import { BsDropdownModule } from 'ngx-bootstrap';
import { FormatPhonePipe } from './core/pipes/phone.pipe';
import { ErrorsModule } from './core/errors/errors.module';
import { environment as env } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { OfflinePopupComponent } from './offline-popup/offline-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElModule } from 'element-angular';
import { MessageActionsComponent } from './components/message-actions/message-actions.component';
import { HelperService } from "./shared/helper.service";
import { FaqModal } from "./components/faq-modal/faq-modal.component";
import { SmsModal } from "./components/sms-modal/sms-modal.component";
import { InactivityLogoutPopupComponent } from "./inactivity-logout-popup/inactivity-logout-popup.component"
import { OperatorLoginSessionPopupComponent } from "./operator-login-session-popup/operator-login-session-popup.component"
import { ChangePasswordComponent } from "./change-password/change-password.component"
import { SyncActiveCampaign } from './components/sync-active-campaign/sync-active-campaign.component'
import { SyncShopifyComponent } from './components/sync-shopify/sync-shopify.component';
import { BlockMessageComponent } from './components/block-message/block-message.component'


@NgModule({
  declarations: [
    AppComponent,
    MessageDetailsComponent,
    OperatorComponent,
    LoginComponent,
    ManagerComponent,
    ProfileComponent,
    ResetPasswordComponent,
    LogoutPopupComponent,
    OfflinePopupComponent,
    MessageActionsComponent,
    FaqModal,
    SmsModal,
    InactivityLogoutPopupComponent,
    OperatorLoginSessionPopupComponent,
    ChangePasswordComponent,
    SyncActiveCampaign,
    SyncShopifyComponent,
    BlockMessageComponent
  ],
  imports: [
    BrowserModule,
    UserIdleModule.forRoot({idle: 3600, timeout: 10, ping: null}),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    // JsonpModule,
    NgbModule.forRoot(),
    ToastyModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    GuestPanelModule,
    MessageQueueModule, 
    InterceptorModule,
    StartupModule,
    RegisterModule,
    DropDownsModule,
    BsDropdownModule.forRoot(),
    ErrorsModule.forRoot({
      enabled: true, sentry: {
        environment: env.sentry_environment,
        dsn: env.sentry_dsn
      }, http: { enabled: true }
    }),
    BrowserAnimationsModule,
    ElModule.forRoot(),
  ],
  providers: [
    OperatorViewService,
    HelperService
  ],
  bootstrap: [AppComponent],
  exports: [
    LogoutPopupComponent,
    OfflinePopupComponent,
    InactivityLogoutPopupComponent,
    OperatorLoginSessionPopupComponent
  ],
  entryComponents: [
    LogoutPopupComponent,
    OfflinePopupComponent,
    FaqModal,
    SmsModal,
    InactivityLogoutPopupComponent,
    OperatorLoginSessionPopupComponent,
    SyncActiveCampaign,
    SyncShopifyComponent,
    BlockMessageComponent

  ]

})
export class AppModule { }
